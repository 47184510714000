









































import Vue from 'vue';
import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { Component, Prop } from 'vue-property-decorator';
import { Notification } from '@/models';
import { format } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class NotificationListItem extends Vue {
  @Prop({ required: true })
  notification: Notification;
  @Prop({ default: false })
  selected: boolean;
  @Prop({ default: false })
  mini: boolean;

  onClick(): void {
    this.$emit('click', this.notification);
  }

  get date(): string {
    return format(this.notification.time, DATE_FORMAT_ISO);
  }

  async dismiss(): Promise<void> {
    this.$emit('dismiss', this.notification.id);
  }
}
