























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Notification } from '@/models';
import { NotificationService } from '@/lib/services';
import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import NotificationListItem from '@/components/NotificationListItem.vue';
import { format } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    NotificationListItem,
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class NotificationsPage extends Vue {
  selected: Notification | null = null;
  notifications: Notification[] = [];

  async mounted(): Promise<void> {
    this.notifications = await NotificationService.all();
    if (this.notifications.length > 0) {
      this.selected = this.notifications[0];
    }
  }

  select(notification: Notification): void {
    this.selected = notification;
  }

  get date(): string {
    if (!this.selected) return '';
    return format(this.selected.time, DATE_FORMAT_ISO);
  }

  async dismiss(id: string): Promise<void> {
    try {
      await NotificationService.dismiss(id);
      this.notifications = this.notifications.filter((e) => e.id !== id);
      this.selected = null;
    } catch (e) {
      this.$toaster.error('Cannot dismiss notification', unwrapError(e));
    }
  }

  async dismissAll(): Promise<void> {
    try {
      await NotificationService.dismissAll();
      this.notifications = [];
      this.selected = null;
    } catch (e) {
      this.$toaster.error('Cannot dismiss notification', unwrapError(e));
    }
  }
}
